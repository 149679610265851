import md5 from 'md5';
import { useGlobal, useState, useRef } from 'reactn';
import Header from './../Header/Header.js';
import useQueryParam from './../../hooks/useQueryParam';
import QuestionForm from './../QuestionForm/QuestionForm';
import Keyvisual from './../../images/Keyvisual2024-light.svg';

const access_token = 'c2d6e9fdc432b328140e4bfe8dc511ec';

function Livestream(props) {
	const [query, setQuery] = useQueryParam('password', '');
	const [isAuthenticated, setIsAuthenticated] = useGlobal('isAuthenticated');
	const [passwordValid, setPasswordValid] = useState('');
	const initialPassword = query;
	const [password, setPassword] = useState(initialPassword);
	const passwordInput = useRef(null);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (md5(event.target.value) === access_token) {
			setPasswordValid('is-valid');
			setTimeout(() => {
				setIsAuthenticated(1);
			}, 2000);
		} else {
			setPasswordValid('is-invalid');
		}
	};
	if (password === access_token) {
		setIsAuthenticated(1);
	}

	const video_url =
		'https://start.video-stream-hosting.de/player.html?serverapp=ideenplantage-live&streamname=bvb_auftakt_25.smil';

	return (
		<>
			<Header />
			<div
				className={
					isAuthenticated
						? 'container-fluid bg-main py-3 py-lg-5 px-lg-5'
						: 'container bg-main py-lg-5 py-3 px-lg-5'
				}>
				<div className={isAuthenticated ? 'row justify-content-center' : 'row'}>
					<div
						className={
							isAuthenticated ? 'col-12 col-lg-9 mb-3' : 'col-12 mb-3'
						}>
						{isAuthenticated ? (
							<h1 style={{ color: '#F2591A' }}>Schön, dass du dabei bist!</h1>
						) : (
							<h1 style={{ color: '#F2591A' }}>Herzlich willkommen!</h1>
						)}
					</div>
				</div>
				{isAuthenticated ? (
					<>
						<div className='row justify-content-center'>
							<div className='col-12 col-lg-9'>
								<div className='embed-responsive embed-responsive-16by9'>
									<iframe
										frameBorder='0'
										title='Jahresauftakt 2025'
										width='845'
										height='475'
										className='embed-responsive-item videoiframe'
										allowFullScreen
										src={video_url}
										referrerPolicy='strict-origin'
										allowFullScreen
										xwebkitairplay='allow'></iframe>
								</div>
							</div>
						</div>
						<div className='row justify-content-center'>
							<div className='col-12 col-lg-9 mt-4 '>
								{/* <QuestionForm /> */}
							</div>
						</div>
					</>
				) : (
					<>
						<p className='lead'>
							Am 17. Januar geht es um 14 Uhr los – gemeinsam starten wir in ein
							neues Jahr voller Tatkraft.
						</p>
						<p>
							{' '}
							Gib einfach dein Passwort ein und sei dabei! Die Passworteingabe
							funktioniert ab Freitag, 17. Januar.
						</p>

						<form onSubmit={(e) => handleSubmit(e)}>
							<div className='form-row justify-content-center'>
								<div className='col-12 col-md-6 my-5'>
									<input
										type='password'
										placeholder='Ihr Passwort'
										className={'form-control form-control-lg ' + passwordValid}
										onKeyPress={(e) => {
											e.key === 'Enter' && e.preventDefault();
										}}
										ref={passwordInput}
										onChange={(e) => handleSubmit(e)}
										onFocus={(e) => handleSubmit(e)}
									/>
								</div>
							</div>
						</form>
					</>
				)}
			</div>
		</>
	);
}

export default Livestream;
