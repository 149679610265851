import React, { useGlobal } from 'reactn';
import Logo from './../../images/logo-2025.png';


const Header = () => {
	const [isAuthenticated, setIsAuthenticated] = useGlobal('isAuthenticated');

	return (
		<>
			<div
				className={
					isAuthenticated ? 'container-fluid px-0 bg-header' : 'container px-0'
				}>
				{isAuthenticated ? (
					<nav className='navbar navbar-expand-lg navbar-light p-3 justify-content-center'>
						<div className='col-12'>
							<div
								className={
									isAuthenticated
										? 'row justify-content-between align-items-center'
										: 'row'
								}>
								<div className='col px-0 px-lg-4'>
									<a
										to='/'
										className='navbar-brand'>
										<img
											src={Logo}
											alt='Berliner Volksbank Logo'
											className='logo'
										/>
									</a>
								</div>
								<div className='col px-0 px-lg-4 text-right'>
									<p
										className='mb-0'
										style={{
											fontWeight: '900',
											fontSize: '1rem',
											color: 'white',
										}}>
										Virtueller Jahresauftakt 2025
									</p>
								</div>
							</div>
						</div>
					</nav>
				) : (
					<></>
				)}
				{isAuthenticated === 0 && (
					<div className='col-12 px-0 keyvisual_wrapper bg-header angled-header'>
						<>
							<img
								src={Logo}
								alt='Berliner Volksbank Logo'
								className='bvb-logo'
							/>
							<div className='pl-3 pl-xl-5 pt-20'>
								<p
									className='typoclaim'>
									Virtueller
									<br />
									Jahresauftakt 2025
								</p>
							</div>
						</>
					</div>
				)}
			</div>
		</>
	);
};

export default Header;
